import { render, staticRenderFns } from "./PreCadastroEdit.vue?vue&type=template&id=563522f0&scoped=true&"
import script from "./PreCadastroEdit.vue?vue&type=script&lang=js&"
export * from "./PreCadastroEdit.vue?vue&type=script&lang=js&"
import style0 from "./PreCadastroEdit.vue?vue&type=style&index=0&id=563522f0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563522f0",
  null
  
)

export default component.exports
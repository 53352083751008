<template>
  <div id="formulario-edit-info">   
    <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
    <vx-card>
        <div class="vx-row mb-2">
            <div class="vx-col w-1/2 ">                    
                <small>STATUS:</small><h5> {{data_local.statusPreCadastro}} </h5>
            </div>
            <div class="vx-col w-1/2 ">                    
                <vs-button class="ml-auto mt-2" size="small" @click="gravar" color="success" v-if="exibirGravar">GRAVAR</vs-button>                
                <vs-button class="ml-4 mt-2" size="small" @click="alterarStatus('EM ANALISE')" color="primary" v-if="exibirEnviarParaAnalise">ENVIAR PARA ANÁLISE</vs-button>
                <vs-button class="ml-4 mt-2" size="small" @click="alterarStatus('APROVADO')" color="success" v-if="exibirAprovar">APROVAR</vs-button>
                <vs-button class="ml-4 mt-2" size="small" @click="alterarStatus('REPROVADO')" color="danger" v-if="exibirReprovar">REPROVAR</vs-button>
                <vs-button class="ml-4 mt-2" size="small" @click="alterarStatus('RASCUNHO')" color="warning" v-if="exibirPendente">VOLTAR PARA RASCUNHO</vs-button>
                <vs-button class="ml-4 mt-2" size="small" @click="excluir" v-show="isDeletable" color="danger">Excluir</vs-button>                            
                <vs-button class="ml-4 mt-2" size="small" type="border" color="dark" @click="voltar">Voltar</vs-button>             
            </div>
            
        </div>
        <vs-tabs alignment="fixed" style="height: calc(75vh)">
            <vs-tab label="Dados Básicos">                
                <div class="vx-row mt-2">
                    <div class="vx-col w-1/2"><label class="vs-input--label">CPF / CNPJ</label></div>                         
                    <div class="vx-col w-1/4" v-if="data_local.dataHoraCadastroFormatada"><label class="vs-input--label">Cadastro</label></div>                         
                    <div class="vx-col w-1/4" v-if="data_local.dataHoraUltimaAlteracaoFormatada"><label class="vs-input--label">Alteração</label></div>                         
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-1/2">
                        <vs-input class="w-full" v-model="data_local.CNPJCPF" :maxlength="60"/> 
                    </div>     
                    <div class="vx-col w-1/4" v-if="data_local.dataHoraCadastroFormatada">{{data_local.dataHoraCadastroFormatada}}</div>                         
                    <div class="vx-col w-1/4" v-if="data_local.dataHoraUltimaAlteracaoFormatada">{{data_local.dataHoraUltimaAlteracaoFormatada}}</div>                         
                </div>
                <div class="vx-row mt-2">
                    <div class="vx-col w-1/2"><label class="vs-input--label">Razão Social</label></div>     
                    <div class="vx-col w-1/2"><label class="vs-input--label">Nome</label></div>                 
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-1/2">
                        <vs-input class="w-full" v-model="data_local.razaoSocial" :maxlength="255"/> 
                    </div>     
                    <div class="vx-col w-1/2">
                        <vs-input class="w-full" v-model="data_local.nome" :maxlength="255"/>   
                    </div>     
                </div>       

                <div class="vx-row mt-2">
                     <div class="vx-col w-5/6"><label class="vs-input--label">Endereço</label></div>     
                    <div class="vx-col w-1/6"><label class="vs-input--label">Número</label></div>  
                </div>    
                <div class="vx-row">
                     <div class="vx-col w-5/6">
                        <vs-input class="w-full" v-model="data_local.endereco" :maxlength="500"/> 
                    </div>                 
                    <div class="vx-col w-1/6">
                        <vs-input class="w-full" v-model="data_local.numeroEndereco" :maxlength="45"/> 
                    </div>   
                </div>
                <div class="vx-row mt-2">
                    <div class="vx-col w-1/3"><label class="vs-input--label">Complemento</label></div>         
                    <div class="vx-col w-1/3"><label class="vs-input--label">Bairro</label></div>         
                    <div class="vx-col w-1/3"><label class="vs-input--label">CEP</label></div>         
                </div>
                <div class="vx-row">
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.complementoEndereco" :maxlength="100"/> 
                    </div>
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.bairro" :maxlength="100"/> 
                    </div>
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.cep" :maxlength="9"/> 
                    </div>     
                </div>
                <div class="vx-row mt-2">                    
                    <div class="vx-col w-1/2"><label class="vs-input--label">Cidade</label></div>     
                    <div class="vx-col w-1/2"><label class="vs-input--label">Estado</label></div>                 
                </div>    
                <div class="vx-row">                    
                    <div class="vx-col w-1/2">
                        <vs-input class="w-full" v-model="data_local.cidade" :maxlength="255"/> 
                    </div>     
                    <div class="vx-col w-1/2">
                        <v-select                                               
                            :options="CONSTANTE_UFS" 
                            v-model="data_local.estado"    
                            :clearable="false"
                            :reduce="r => r.data"                                                                         
                            class="vs-input--label mr-2">
                        </v-select>   
                    </div>     
                </div>          
            </vs-tab>  
            <vs-tab label="Contatos">
                <div class="vx-row mt-2">
                    <div class="vx-col w-1/3"><label class="vs-input--label">Nome Contato Financeiro</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Telefone Contato Financeiro</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">E-mail Contato Financeiro</label></div>          
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.nomeContato1" :maxlength="255"/> 
                    </div>         
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.telefone" :maxlength="255"/> 
                    </div>         
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.email" :maxlength="255"/> 
                    </div>   
                </div>   

                <div class="vx-row mt-2">
                    <div class="vx-col w-1/3"><label class="vs-input--label">Nome Contato 2</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Telefone Contato 2</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">E-mail Contato 2</label></div>     
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.nomeContato2" :maxlength="255"/> 
                    </div>         
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.telefone2" :maxlength="255"/> 
                    </div>       
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.email2" :maxlength="255"/> 
                    </div>         
                    
                </div>  
                <div class="vx-row mt-2">
                    <div class="vx-col w-1/3"><label class="vs-input--label">Nome Contato 3</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Telefone Contato 3</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">E-mail Contato 3</label></div>     
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.nomeContato3" :maxlength="255"/> 
                    </div>         
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.telefone3" :maxlength="255"/> 
                    </div>    
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.email3" :maxlength="255"/> 
                    </div> 
                </div>
                <div class="vx-row mt-2">
                    <div class="vx-col w-1/3"><label class="vs-input--label">Nome Contato Frota</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Telefone Contato Frota</label></div>                 
                    <div class="vx-col w-1/3"><label class="vs-input--label">Email Contato Frota</label></div>                 
                </div> 
                <div class="vx-row mt-2">                    
                    <div class="vx-col w-1/3"><vs-input class="w-full" v-model="data_local.nomeResponsavelFrota" :maxlength="255"/></div>     
                    <div class="vx-col w-1/3"><vs-input class="w-full" v-model="data_local.telefonesResponsavelFrota" :maxlength="255"/></div>                 
                    <div class="vx-col w-1/3"><vs-input class="w-full" v-model="data_local.emailResponsavelFrota" :maxlength="255"/></div>                     
                </div>
            </vs-tab>         
            <vs-tab label="Dados do Contrato">   
                <vs-button class="mt-2 mb-2" size="small" @click="imprimir()" color="success" v-if="data_local && data_local.id">GERAR CONTRATO</vs-button>  
                <div class="vx-row">                   
                    <div class="vx-col w-1/6"><label class="vs-input--label">Senha para Bloqueio</label></div>                 
                </div>    
                <div class="vx-row">                      
                    <div class="vx-col w-1/6">                        
                        <vs-input class="w-full" v-model="data_local.senhaBloqueio" :maxlength="255"/>
                    </div>     
                </div>     
                 <div class="vx-row mt-2">
                    <div class="vx-col w-1/6">
                        <div class="vx-col w-1/2"><label class="vs-input--label">Versão</label></div>     
                    </div>  
                 </div>   
                 <div class="vx-row">
                    <div class="vx-col w-1/6">
                        <v-select                                               
                            :options="CONSTANTE_TIPO_VERSOES" 
                            v-model="data_local.versaoProduto"    
                            :clearable="false"
                            :reduce="r => r.data"                                                                         
                            class="vs-input--label">
                        </v-select>   
                    </div>     
                    <div class="vx-col w-5/6 flex items-center" v-if="data_local.versaoProduto === 'PRO'">
                        <vs-checkbox v-model="data_local.temControleFrota" class="w-full"><small>Controle de Frotas</small></vs-checkbox>
                        <vs-checkbox v-model="data_local.temControleViagem" class="w-full"><small>Controle de Viagens</small></vs-checkbox>
                        <vs-checkbox v-model="data_local.temRoteirizacao" class="w-full"><small>Roteirização</small></vs-checkbox>
                        <vs-checkbox v-model="data_local.temControlePneus" class="w-full"><small>Controle de Pneus</small></vs-checkbox>
                    </div>     
                </div>    

                <div class="vx-row mt-2">
                    <div class="vx-col w-1/4">
                        <label class="vs-input--label mr-2">Data de Início</label>
                    </div>  
                    <div class="vx-col w-1/4">
                        <label class="vs-input--label mr-2">N. Meses</label>
                    </div>  
                    <div class="vx-col w-1/4">
                        <label class="vs-input--label mr-2">Dia do Vencimento</label>
                    </div>                      
                </div>
                <div class="vx-row">
                    <div class="vx-col w-1/4">                        
                        <flat-pickr v-model="data_local.dataInicioContrato" :config="configDatePicker"/>
                    </div>     
                    <div class="vx-col w-1/4">
                        <vs-input class="w-full" v-model="data_local.duracaoContrato" type="number"/> 
                    </div>     
                    <div class="vx-col w-1/4">                        
                          <v-select                                               
                            :options="CONSTANTE_VENCIMENTOS" 
                            v-model="data_local.diaVencimento"    
                            :clearable="false"
                            :reduce="r => r.data"                                                                         
                            class="v-select">
                        </v-select>   
                    </div> 
                </div>

                <div class="vx-row mt-2">
                    <div class="vx-col w-1/4">
                        <label class="vs-input--label mr-2">Representante Legal</label>
                    </div>  
                    <div class="vx-col w-1/4">
                        <label class="vs-input--label mr-2">CPF do Representante</label>
                    </div>  
                    <div class="vx-col w-1/4">
                        <label class="vs-input--label mr-2">Email aceite do contrato</label>
                    </div>  
                </div>      
                <div class="vx-row">
                    <div class="vx-col w-1/4">                        
                        <vs-input class="w-full" v-model="data_local.nomeRepresentante"/> 
                    </div>     
                    <div class="vx-col w-1/4">
                        <vs-input class="w-full" v-model="data_local.cpfRepresentante"/> 
                    </div>     
                    <div class="vx-col w-1/4">
                        <vs-input class="w-full" v-model="data_local.emailAceiteFormularioPedido"/>   
                    </div>    
                    
                </div>              
            </vs-tab>            
           
            <vs-tab :label="'Anexos (' + dadosDaGridAnexos.length + ')'" v-if="data_local.id">
                <div class="vx-row">
                    <div class="vx-col md:w-3/4 sm:w-3/4 w-full">
                        <vx-card class="mt-2" v-if="dadosDaGridAnexos.length">
                            <div class="flex items-center flex-start">
                                <vs-button class="mt-2 mr-2 mb-4" size="small" @click="verDocumento" color="primary" icon="pageview">Ver arquivo</vs-button> 
                                <vs-button class="mt-2 mb-4" size="small" @click="excluirAnexo" color="danger" icon="delete" v-if="exibirExcluir">Excluir Arquivo</vs-button> 
                            </div>
                            
                            <ag-grid-vue
                                ref="agGridTableAnexos"        
                                :gridOptions="configuracaoGridAnexo.gridOptions"
                                class="ag-theme-material ag-grid-table ag-grid-altura"
                                :columnDefs="configuracaoGridAnexo.columnDefs"
                                :defaultColDef="configuracaoGridAnexo.defaultColDef"
                                :rowData="dadosDaGridAnexos"
                                :headerHeight="25"
                                :rowHeight="25"
                                rowSelection="single"
                                @grid-ready="onGridReadyAnexo"                            
                                colResizeDefault="shift"                    
                                :animateRows="true"
                                :floatingFilter="false"
                                :pagination="false"
                                :paginationAutoPageSize="false"
                                :suppressPaginationPanel="true"
                                >
                            </ag-grid-vue>
                            
                            
                        </vx-card> 
                    </div>
                    <div class="vx-col md:w-1/4 sm:w-1/4 w-full flex-items-center">
                        <vx-card class="mt-2">
                            <vs-upload :action="getUrlPost" @on-success="successUploadFile" @on-delete="limparMsgErroUpload"
                                    text="Anexar ..." :data="dadosUpload" limit="1" multiple="false"  fileName="nomeArquivo"
                                    automatic single-upload/>
                            <vs-alert closable title="Atenção" color="warning" :active.sync="exibirMensagemResultadoUpload">{{mensagemResultadoUpload}}</vs-alert>
                        </vx-card> 
                    </div>
                </div>
            </vs-tab>
            <vs-tab label="Aprovação" v-if="data_local.id">
                <vx-tooltip text="Enviar Para Aprovação" position="bottom">              
                    <vs-button @click="enviarAprovacao" icon="thumb_up_alt" color="primary" v-if="data_local.hasOwnProperty('id') && data_local.statusPreCadastro === 'APROVADO' ">Enviar para Aprovação para {{ data_local.emailAceiteFormularioPedido }}</vs-button>            
                </vx-tooltip>
                <div class="vx-row mt-4" v-if="data_local.dataEnvioAssinaturaEletronicaFormatada">                    
                    <div class="vx-col w-1/3">Enviado para assinatura em</div>  
                    <div class="vx-col w-1/3">Assinado em</div>  
                </div>
                <div class="vx-row">                                        
                    <div class="vx-col w-1/3">   
                        <p v-if="data_local.dataEnvioAssinaturaEletronicaFormatada"> {{ data_local.dataEnvioAssinaturaEletronicaFormatada }}</p>
                    </div>
                    <div class="vx-col w-1/3">   
                        <p v-if="data_local.dataAssinaturaEletronicaFormatada"> {{ data_local.dataAssinaturaEletronicaFormatada }}</p>
                    </div>
                </div>                
                <div class="vx-row mt-2 " v-if="data_local.urlParaAssinaturaEletronica">
                    <div class="vx-col w-full flex items-center">  
                        <vs-button @click="copyURL" color="primary" icon="content_copy"></vs-button> 
                        <p class="ml-4"> Link para aprovação: <strong> {{data_local.urlParaAssinaturaEletronica}} </strong></p>                                
                    </div>
                </div>
            </vs-tab>
        </vs-tabs> 
    </vx-card>  
  </div>
</template>

<script>
import axios from "@/axios.js"
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Portuguese as PortugueseLocale} from 'flatpickr/dist/l10n/pt.js';
import { AgGridVue } from "ag-grid-vue"
import generico from "@/generico.js"

import { CONSTANTE_UFS } from '@/constantesComboBox.js'
import { CONSTANTE_TIPO_VERSOES } from '@/constantesComboBox.js'
import { CONSTANTE_TIPO_CONTRATO } from '@/constantesComboBox.js'

const CONSTANTE_VENCIMENTOS = [	
	{label:"5", data:"5"},			
	{label:"10", data:"10"},
    {label:"15", data:"15"},
    {label:"20", data:"20"},
    {label:"25", data:"25"}
];


const URL_SERVLET = "/rest/PreCadastro";
const NAME_ROUTE_SEARCH = "cadastro-pre-cadastro-pesquisar";


export default {    
  components: {  
    AgGridVue,
    flatPickr
  },  
  props: {
    registroOriginal: {
      type: Object,
      required: false,
      default: function () {
             return { CNPJCPF: null, razaoSocial: null, nome: null, endereco: null, cidade: null, estado: null, cep: null,  telefone: null,
            telefone2: null, telefone3: null, email: null, email2: null, email3: null,  status: 'ATIVO', 
            senhaBloqueio: null, versaoProduto: 'BASIC', temControleFrota: false, temControlePneus: false, temControleViagem: false, 
            temRoteirizacao: false, tipoContrato: 'MENSALIDADE', dataInicioContrato: null, duracaoContrato: null, diaVencimento: null, 
            nomeRepresentante: null, cpfRepresentante: null, emailAceiteFormularioPedido: null, statusPreCadastro: null,
            nomeResponsavelFrota: null, telefonesResponsavelFrota: null, emailResponsavelFrota: null,
            numeroEndereco: null, bairro: null, nomeContato1: null, nomeContato2: null, nomeContato3: null, complementoEndereco : null }
       }
    },
  },
  data() {
    return {        
        errorsValidacao: [],  
        data_local: JSON.parse(JSON.stringify(this.registroOriginal)),      
        configDatePicker: {          
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "Y-m-d", 
            altInputClass: "vs-inputx vs-input--input normal hasValue text-center",
            locale: PortugueseLocale
        },
        dadosDaGridAnexos: [],
        configuracaoGridAnexo: { 
          gridApi: null, gridOptions: {}, 
          defaultColDef: { sortable: true, resizable: true, suppressMenu: true  },
          columnDefs: [                                
            { headerName: 'Arquivo',  field: 'nomeDocumento', width: 400},        
            { headerName: 'Criado às',  field: 'dataUploadFormatada', width: 200}            
          ]
        },
        dadosUpload: {
           clientId: this.registroOriginal.id,                    
           destino: "pre-cadastro",
           empresaId: this.$store.state.AppActiveUser.idEmpresa,            
           outputFormat: 'JSON',
           nomeArquivo: 'Teste seção 2'
        },
        mensagemResultadoUpload: null,
        exibirMensagemResultadoUpload: false,     
    }
  },    
  mounted(){
    if (!this.registroOriginal.hasOwnProperty('id')) {          
          this.data_local.senhaBloqueio = this.getRandomInt(10000,99999);
    } else {
        this.fetchListaDocumentos();
    }
  },
  computed: {        
    isDeletable() {
       return this.data_local != null && this.data_local.hasOwnProperty("id") && this.data_local.statusPreCadastro === 'RASCUNHO';       
    },   
    getUrlPost() {
      return axios.defaults.baseURL + "/UploadDocumentos?processUploadedFile";
    },
    exibirExcluir(){
        if (this.data_local && this.data_local.id && this.data_local.statusPreCadastro === 'APROVADO') {
            if (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') {
                return true.valueOf;
            }
            return false;
        } else if (this.data_local && this.data_local.id && this.data_local.statusPreCadastro === 'REPROVADO') {
            if (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') {
                return true.valueOf;
            }
            return false;
        } else {
            return true;
        }
    },
    exibirGravar(){
        if (this.data_local && this.data_local.id && this.data_local.statusPreCadastro === 'APROVADO') {
            if (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') {
                return true.valueOf;
            }
            return false;
        } else if (this.data_local && this.data_local.id && this.data_local.statusPreCadastro === 'REPROVADO') {
            if (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') {
                return true.valueOf;
            }
            return false;
        } else {
            return true;
        }
    },
    exibirEnviarParaAnalise(){
        if (this.data_local && this.data_local.id && this.data_local.statusPreCadastro === 'RASCUNHO') {
            return true;
        } else {
            return false;
        }
    },
    exibirAprovar(){
        if (this.data_local && this.data_local.id && this.data_local.statusPreCadastro === 'EM ANALISE') {
            return true;
        } else {
            return false;
        }
    },
    exibirReprovar(){
        if (this.data_local && this.data_local.id && this.data_local.statusPreCadastro === 'EM ANALISE') {
            return true;
        } else {
            return false;
        }
    },
    exibirPendente(){
        if (this.data_local && this.data_local.id && this.data_local.statusPreCadastro === 'EM ANALISE') {            
            return true;
        } else {
            if (this.data_local && this.data_local.id && this.data_local.statusPreCadastro !== 'RASCUNHO' 
            && (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR' || this.$store.state.AppActiveUser.perfil === 'EMPRESA')) {
                return true;
            }
            return false;
        }
    }
    
  },
  created(){
      this.CONSTANTE_UFS = CONSTANTE_UFS;
      if (this.$store.state.AppActiveUser.idEmpresa === 1) {
            this.CONSTANTE_TIPO_VERSOES = CONSTANTE_TIPO_VERSOES.concat([ {label:"Fast", data:"FAST"}]);
      } else {
            this.CONSTANTE_TIPO_VERSOES = CONSTANTE_TIPO_VERSOES;
      }
      this.CONSTANTE_TIPO_CONTRATO = CONSTANTE_TIPO_CONTRATO;   
      this.CONSTANTE_VENCIMENTOS = CONSTANTE_VENCIMENTOS;        
  }, 
  methods: {  
    async copyURL() {
        try {
            await window.navigator.clipboard.writeText(this.data_local.urlParaAssinaturaEletronica);            
        } catch(e) {
            /* eslint-disable no-console */ console.log("Erro copyURL " + e);        
        }
    },
    imprimir() {
        let self_ = this;
        this.$vs.dialog({
            type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja imprimir o contrato do cliente  '
                + self_.data_local.nome + ' ? ', acceptText: 'Sim', cancelText: 'Não', accept: this.imprimirRequest
        });
    },
    imprimirRequest() {
        this.$vs.loading();  
        const params = new URLSearchParams();                
        params.append('id', this.data_local.id);   
        
        axios.post(URL_SERVLET + "/GerarContrato", params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true, responseType: 'arraybuffer' } )
        .then((response) => {
            this.$vs.loading.close();

            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', this.data_local.nome + '.docx');
                document.body.appendChild(fileLink);
                fileLink.click();
            }
        })
        .catch(() => { 
            this.$vs.loading.close();                            
        })  
    },  
    findById() {
        const params = new URLSearchParams();        
        params.append('id', this.data_local.id);           
                
        axios.post(URL_SERVLET + "/Recuperar", params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {                
                this.$router.push({ path: '/login' });                       
            } else {                
                 this.data_local = response.data.PreCadastro;                 
            }
        })
        .catch((error) => { 
            /* eslint-disable no-console */  console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    }, 
    getRandomInt(min, max) {
      min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    },
    excluir() {
        this.$vs.dialog({
          type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja excluir ?', acceptText: 'Sim', cancelText: 'Não', accept: this.excluirRequest
        });
    },
    excluirRequest() {
        this.$vs.loading();  
        const params = new URLSearchParams();        
        params.append('id', this.data_local.id);   
                
        axios.post(URL_SERVLET+'/Remover', params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/login' });
            } else {                
                if (response.data.Resultado.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.Resultado.error, acceptText: 'OK'})
                } else {
                    this.$router.push({ name: NAME_ROUTE_SEARCH })
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    },
    alterarStatus(novoStatus) {        
        
        this.$vs.loading();  
        const params = new URLSearchParams();        
        params.append('id', this.data_local.id);               
        params.append('statusPreCadastro', this.data_local.statusPreCadastro);               
        params.append('novoStatus', novoStatus);     
        
        axios.post(URL_SERVLET+'/AlterarStatus', params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/login' });
            } else { 
                this.findById();                   
                if (response.data.Resultado.hasOwnProperty("error")) {
                    this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.Resultado.error, acceptText: 'OK'})
                } else {
                    this.$vs.dialog({ color: 'success', title: 'Atenção', text: response.data.Resultado.mensagem, acceptText: 'OK'})
                }                 
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
        
    },
    gravar() {        
        if (this.formularioValido()) { 
            this.$vs.loading();  
            const params = new URLSearchParams();        
            
           if (this.data_local.hasOwnProperty("id")) {
                params.append('id', this.data_local.id);               
            }  

            params.append('nome', this.data_local.nome);      
            params.append('razaoSocial', this.data_local.razaoSocial);      
            params.append('CNPJCPF', this.data_local.CNPJCPF);
            
            params.append('versaoProduto', this.data_local.versaoProduto);
            params.append('telefone', this.data_local.telefone);     
            if (this.data_local.telefone2) {
                params.append('telefone2', this.data_local.telefone2);     
            }
            if (this.data_local.telefone3) {
                params.append('telefone3', this.data_local.telefone3);     
            }
            params.append('email', this.data_local.email);     
            if (this.data_local.email2) {
                params.append('email2', this.data_local.email2);     
            }
            if (this.data_local.email3) {
                params.append('email3', this.data_local.email3);     
            }

            params.append('senhaBloqueio', this.data_local.senhaBloqueio);     
            params.append('endereco', this.data_local.endereco);     
            params.append('cidade', this.data_local.cidade);     
            params.append('estado', this.data_local.estado);     
            params.append('cep', this.data_local.cep);     
            if (this.data_local.numeroEndereco) {
                params.append('numeroEndereco', this.data_local.numeroEndereco);
            }
            if (this.data_local.bairro) {
                params.append('bairro', this.data_local.bairro);
            }
            if (this.data_local.nomeContato1) {   
                params.append('nomeContato1', this.data_local.nomeContato1);
            }
            if (this.data_local.nomeContato2) {   
                params.append('nomeContato2', this.data_local.nomeContato2);
            }
            if (this.data_local.nomeContato3) {   
                params.append('nomeContato3', this.data_local.nomeContato3);
            }
            if (this.data_local.complementoEndereco) {   
                params.append('complementoEndereco', this.data_local.complementoEndereco);
            }
            params.append('senhaBloqueio', this.data_local.senhaBloqueio);                 
            
            params.append('tipoContrato', this.data_local.tipoContrato);
            params.append('duracaoContrato', this.data_local.duracaoContrato);
            params.append('diaVencimento', this.data_local.diaVencimento);                
            params.append('dataInicioContrato', this.data_local.dataInicioContrato);
            
            if (this.data_local.versaoProduto === 'PRO') {
                params.append('temControleFrota', this.data_local.temControleFrota);
                params.append('temControlePneus', this.data_local.temControlePneus);
                params.append('temControleViagem', this.data_local.temControleViagem);
                params.append('temRoteirizacao', this.data_local.temRoteirizacao);
            } 

            params.append('nomeRepresentante', this.data_local.nomeRepresentante);                
            params.append('cpfRepresentante', this.data_local.cpfRepresentante);                
            params.append('emailAceiteFormularioPedido', this.data_local.emailAceiteFormularioPedido);  
            
            if (this.data_local.nomeResponsavelFrota) {
                params.append('nomeResponsavelFrota', this.data_local.nomeResponsavelFrota);
            }
            if (this.data_local.telefonesResponsavelFrota) {
                params.append('telefonesResponsavelFrota', this.data_local.telefonesResponsavelFrota);
            }
            if (this.data_local.emailResponsavelFrota) {
                params.append('emailResponsavelFrota', this.data_local.emailResponsavelFrota);
            }
  
            axios.post(URL_SERVLET+'/Gravar', params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
               if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/login' });
                } else {                    
                    if (response.data.Resultado.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.Resultado.error, acceptText: 'OK'})
                    } else {
                        this.$router.push({ name: NAME_ROUTE_SEARCH })
                    }                   
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
            })  
        }
         
    },
    voltar() {
        this.$router.push({
            name: NAME_ROUTE_SEARCH                     
        })
    },
    formularioValido() {
        this.$refs.errosComponent.iniciar();
        this.errorsValidacao = [];
      
        if (!this.data_local.CNPJCPF) {
            this.errorsValidacao.push("CPF/CNPJ obrigatório");
        } else {
            if (!generico.isCPFValido(this.data_local.CNPJCPF) && !generico.isCNPJValido(this.data_local.CNPJCPF)) {
                this.errorsValidacao.push("CPF/CNPJ inválido");
            }
        }

        if (!this.data_local.razaoSocial) {
            this.errorsValidacao.push("Razão Social obrigatório");
        }

        if (!this.data_local.nome) {
            this.errorsValidacao.push("Nome obrigatório");
        }

        if (!this.data_local.endereco) {
            this.errorsValidacao.push("Endereço obrigatório");
        }

        if (!this.data_local.cidade) {
            this.errorsValidacao.push("Cidade obrigatório");
        }

        if (!this.data_local.estado) {
            this.errorsValidacao.push("Estado obrigatório");
        }

        if (!this.data_local.cep) {
            this.errorsValidacao.push("CEP obrigatório");
        } else if (!generico.isCEPValido(this.data_local.cep)) {
            this.errorsValidacao.push("CEP inválido");
        }

        if (!this.data_local.telefone) {
            this.errorsValidacao.push("Telefone obrigatório");
        }

        if (!this.data_local.email) {
            this.errorsValidacao.push("Email obrigatório");
        } else if (generico.emailInvalido(this.data_local.email)) {
            this.errorsValidacao.push("Email inválido");   
        }

        if (this.data_local.email2 && generico.emailInvalido(this.data_local.email2)) {
            this.errorsValidacao.push("Email 2 inválido");   
        }

        if (this.data_local.email3 && generico.emailInvalido(this.data_local.email3)) {
            this.errorsValidacao.push("Email 3 inválido");   
        }

        if (!this.data_local.senhaBloqueio) {
            this.errorsValidacao.push("Senha de bloqueio");
        } else if (this.data_local.senhaBloqueio.length < 4) {
            this.errorsValidacao.push("Senha de bloqueio muito curta");
        }

        if (!this.data_local.versaoProduto) {
            this.errorsValidacao.push("Versão obrigatório");
        } 
        
            
        if (!this.data_local.tipoContrato) {
            this.errorsValidacao.push("Tipo do Contrato obrigatório");
        }

        if (!this.data_local.dataInicioContrato) {
            this.errorsValidacao.push("Data de Início do Contrato obrigatório");
        }

        if (!this.data_local.duracaoContrato) {
            this.errorsValidacao.push("Duração do Contrato em meses obrigatório");
        }

        if (!this.data_local.diaVencimento) {
            this.errorsValidacao.push("Dia do Vencimento no Contrato obrigatório");
        } else if (this.data_local.diaVencimento < 1 || this.data_local.diaVencimento > 28) {
            this.errorsValidacao.push("Dia do Vencimento no Contrato deve ser entre 1 e 28");
        }

        if (!this.data_local.nomeRepresentante) {
            this.errorsValidacao.push("Nome do representante obrigatório");
        }

        if (!this.data_local.cpfRepresentante) {
            this.errorsValidacao.push("CPF do representante obrigatório");
        }

        if (!this.data_local.emailAceiteFormularioPedido) {
            this.errorsValidacao.push("Email Aceite do Contrato obrigatório");
        } else if (this.data_local.emailAceiteFormularioPedido && generico.emailInvalido(this.data_local.emailAceiteFormularioPedido)) {
            this.errorsValidacao.push("Email Aceite do Contrato inválido");   
        }

        if (!this.data_local.nomeResponsavelFrota) {
            this.errorsValidacao.push("Nome do responsável pela frota obrigatório");
        }

        if (!this.data_local.telefonesResponsavelFrota) {
            this.errorsValidacao.push("Telefone do responsável pela frota obrigatório");
        }

        if (!this.data_local.emailResponsavelFrota) {
            this.errorsValidacao.push("Email contato frota obrigatório");
        } else if (this.data_local.emailResponsavelFrota && generico.emailInvalido(this.data_local.emailResponsavelFrota)) {
            this.errorsValidacao.push("Email do responsável pela frota inválido");   
        }
       
        if (!this.errorsValidacao.length) {
            return true;
        }   
        return false;
    },
    successUploadFile(evento){              
        var resultadoJSON = JSON.parse(evento.target.response);
        this.mensagemResultadoUpload = resultadoJSON.mensagem;
        this.exibirMensagemResultadoUpload = true;
        this.fetchListaDocumentos(); 
    },
    limparMsgErroUpload() {
      this.mensagemResultadoUpload = null;
      this.exibirMensagemResultadoUpload = false;
    },
    verDocumento() { 
        var itemSelecionados = this.configuracaoGridAnexo.gridApi.getSelectedRows();

        if (itemSelecionados && itemSelecionados.length > 0) {
            let ultimoIndice = itemSelecionados[0].nomeDocumento.lastIndexOf(".") ;
            if ( ultimoIndice != -1) {
                window.open("https://documentos-pre-cadastro-setup.s3.amazonaws.com/" + this.registroOriginal.id + "/" + itemSelecionados[0].id + "." + itemSelecionados[0].nomeDocumento.substring(ultimoIndice+1), "_blank");    
            } else {
                window.open("https://documentos-pre-cadastro-setup.s3.amazonaws.com/" + this.registroOriginal.id + "/" + itemSelecionados[0].id, "_blank");    
            }
            
        } else {
            this.$vs.dialog({ color: 'danger', title: 'Erro', text: 'Selecione um anexo', acceptText: 'OK'})
        }
    },
    fetchListaDocumentos() {
        const params = new URLSearchParams();        
        params.append('outputFormat', 'JSON');
        params.append('id', this.registroOriginal.id);

        axios.post(URL_SERVLET + "/ListarDocumentos", params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {          
              this.dadosDaGridAnexos = [];                   
              if (response.data.Resultado.quantidade > 1) {
                  this.dadosDaGridAnexos = response.data.Resultado.lista.item;
              } else if (response.data.Resultado.quantidade === 1) {
                  this.dadosDaGridAnexos = [];
                  this.dadosDaGridAnexos.push(response.data.Resultado.lista.item);
              } else {
                  this.dadosDaGridAnexos = [];
              }    
        })
        .catch((error) => { 
            /* eslint-disable no-console */ console.log("Erro fetchListaDocumentos " + error);        
        })      
    },  
    onGridReadyAnexo() {
       this.configuracaoGridAnexo.gridApi = this.configuracaoGridAnexo.gridOptions.api;
       this.configuracaoGridAnexo.gridApi.sizeColumnsToFit();      
    },   
    excluirAnexo(){        
        this.$vs.dialog({
            type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja excluir o arquivo ?  '
            , acceptText: 'Sim', cancelText: 'Não', accept: this.excluirAnexoRequest
        });
    }, 
    excluirAnexoRequest() {
        var itemSelecionados = this.configuracaoGridAnexo.gridApi.getSelectedRows();

        if (itemSelecionados && itemSelecionados.length > 0) {
            let currentObj = this;
            this.$vs.loading(); 

            const params = new URLSearchParams();                    
            params.append('idDocumento', itemSelecionados[0].id);   
            params.append('nomeDocumento', itemSelecionados[0].nomeDocumento);   
            params.append('id', this.registroOriginal.id);            
                    
            axios.post(URL_SERVLET + "/ExcluirDocumento", params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {                
                    this.$router.push({ path: '/' });
                } else {
                    currentObj.$vs.loading.close();

                    if (response.data.mensagem.indexOf("erro") != -1) {
                            this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.mensagem, acceptText: 'OK'})
                    } else {
                        this.fetchListaDocumentos();
                    }                   
                }
            })
            .catch((error) => { 
                currentObj.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
            })  
        } else {
            this.$vs.dialog({ color: 'danger', title: 'Erro', text: 'Selecione um anexo', acceptText: 'OK'})
        }
    }, 
    enviarAprovacao() {
        this.$vs.dialog({
          type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja enviar para Assinatura Eletrônica ?', acceptText: 'Sim', cancelText: 'Não', accept: this.enviarAprovacaoRequest
        });
    },
    enviarAprovacaoRequest() {
        this.$vs.loading();  
        const params = new URLSearchParams();        
        params.append('id', this.data_local.id);   
                
        axios.post(URL_SERVLET+'/EnviarParaAprovacao', params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/login' });
            } else {                
                if (response.data.Resultado.hasOwnProperty("error")) {
                    this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.Resultado.error, acceptText: 'OK'})
                } else {
                    this.$vs.dialog({ color: 'danger', title: 'Atenção', text: response.data.Resultado.mensagem, acceptText: 'OK'})
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    },  
  }
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 300px);
      position: relative;      
    } 
</style>